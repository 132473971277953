// extracted by mini-css-extract-plugin
export var animDesktop = "news-more-card-module--animDesktop--92976";
export var animMobile = "news-more-card-module--animMobile--fd2db";
export var body = "news-more-card-module--body--96995";
export var bodyLarge = "news-more-card-module--bodyLarge--3b034";
export var bodyLargeLight = "news-more-card-module--bodyLargeLight--15b1c";
export var bodySmall = "news-more-card-module--bodySmall--bc27e";
export var bold = "news-more-card-module--bold--3cc0d";
export var browedHeader = "news-more-card-module--browedHeader--222aa";
export var card = "news-more-card-module--card--b810b";
export var centerlineButter = "news-more-card-module--centerlineButter--ef135";
export var centerlineHeader = "news-more-card-module--centerlineHeader--fa738";
export var col0 = "news-more-card-module--col0--51ae0";
export var col1 = "news-more-card-module--col1--88270";
export var col10 = "news-more-card-module--col10--05ff2";
export var col11 = "news-more-card-module--col11--f3e55";
export var col12 = "news-more-card-module--col12--e0853";
export var col2 = "news-more-card-module--col2--9037a";
export var col3 = "news-more-card-module--col3--dddeb";
export var col4 = "news-more-card-module--col4--ac811";
export var col5 = "news-more-card-module--col5--5f632";
export var col6 = "news-more-card-module--col6--3aa9f";
export var col7 = "news-more-card-module--col7--08063";
export var col8 = "news-more-card-module--col8--69bbe";
export var col9 = "news-more-card-module--col9--bee29";
export var colLg0 = "news-more-card-module--colLg0--ff132";
export var colLg1 = "news-more-card-module--colLg1--24106";
export var colLg10 = "news-more-card-module--colLg10--0669b";
export var colLg11 = "news-more-card-module--colLg11--dfcae";
export var colLg12 = "news-more-card-module--colLg12--6dc76";
export var colLg2 = "news-more-card-module--colLg2--d6c8d";
export var colLg3 = "news-more-card-module--colLg3--c8a97";
export var colLg4 = "news-more-card-module--colLg4--07653";
export var colLg5 = "news-more-card-module--colLg5--1f756";
export var colLg6 = "news-more-card-module--colLg6--11cfb";
export var colLg7 = "news-more-card-module--colLg7--be7e3";
export var colLg8 = "news-more-card-module--colLg8--8734a";
export var colLg9 = "news-more-card-module--colLg9--3a61a";
export var colMd0 = "news-more-card-module--colMd0--40c13";
export var colMd1 = "news-more-card-module--colMd1--4443a";
export var colMd10 = "news-more-card-module--colMd10--3ff18";
export var colMd11 = "news-more-card-module--colMd11--c7215";
export var colMd12 = "news-more-card-module--colMd12--922eb";
export var colMd2 = "news-more-card-module--colMd2--9bd2d";
export var colMd3 = "news-more-card-module--colMd3--9defa";
export var colMd4 = "news-more-card-module--colMd4--549f3";
export var colMd5 = "news-more-card-module--colMd5--4bd11";
export var colMd6 = "news-more-card-module--colMd6--43469";
export var colMd7 = "news-more-card-module--colMd7--9b433";
export var colMd8 = "news-more-card-module--colMd8--36970";
export var colMd9 = "news-more-card-module--colMd9--0c971";
export var colSm0 = "news-more-card-module--colSm0--3171b";
export var colSm1 = "news-more-card-module--colSm1--d5709";
export var colSm10 = "news-more-card-module--colSm10--25074";
export var colSm11 = "news-more-card-module--colSm11--182bf";
export var colSm12 = "news-more-card-module--colSm12--535ff";
export var colSm2 = "news-more-card-module--colSm2--faccf";
export var colSm3 = "news-more-card-module--colSm3--db1e7";
export var colSm4 = "news-more-card-module--colSm4--5de29";
export var colSm5 = "news-more-card-module--colSm5--e7422";
export var colSm6 = "news-more-card-module--colSm6--81c92";
export var colSm7 = "news-more-card-module--colSm7--8b89c";
export var colSm8 = "news-more-card-module--colSm8--77028";
export var colSm9 = "news-more-card-module--colSm9--d98fa";
export var container = "news-more-card-module--container--ccf24";
export var eyebrow = "news-more-card-module--eyebrow--76d87";
export var featuresContainer = "news-more-card-module--featuresContainer--599a3";
export var filter = "news-more-card-module--filter--7c6e9";
export var filterBox = "news-more-card-module--filterBox--6b4b8";
export var gridContainer = "news-more-card-module--gridContainer--873c8";
export var homepageHeadline = "news-more-card-module--homepageHeadline--f33eb";
export var imageClipped = "news-more-card-module--imageClipped--cfcea";
export var imageClippedEx = "news-more-card-module--imageClippedEx--7450c";
export var imageClippedLeft = "news-more-card-module--imageClippedLeft--73087";
export var imageClippedTopLeft = "news-more-card-module--imageClippedTopLeft--5a88a";
export var imageClippedTopLeftMobile = "news-more-card-module--imageClippedTopLeftMobile--97f30";
export var imageUnClipped = "news-more-card-module--imageUnClipped--fe29f";
export var inherit = "news-more-card-module--inherit--f74d4";
export var internalHeadline = "news-more-card-module--internalHeadline--28bbd";
export var italic = "news-more-card-module--italic--f9b38";
export var leftAlignedButton = "news-more-card-module--leftAlignedButton--c959b";
export var link = "news-more-card-module--link--899ad";
export var linkSmall = "news-more-card-module--linkSmall--23900";
export var monobrow = "news-more-card-module--monobrow--d525b";
export var quote = "news-more-card-module--quote--8383d";
export var sectionSpacer = "news-more-card-module--sectionSpacer--fa7c9";
export var tag = "news-more-card-module--tag--d975c";
export var textInput = "news-more-card-module--textInput--09755";